<template>
  <div class="behaviour-form">
    <form>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("behaviour-form.card-header-origin") }}</h3>
        </div>
        <GenosetInput
          :errors="genosetErrors"
          :initial="initialGenoset"
          :onChange="onGenosetChange"
        />
        <br />
        <GenotypeInput
          :errors="genotypeErrors"
          :initial="initialGenotype"
          :onChange="onGenotypeChange"
        />
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <label for>{{ $l.get("dictionary.repute") }}</label>
        <ul class="centerx">
          <li>
            <vs-radio color="success" v-model="behaviour.repute" vs-value="1">{{
              $l.get("dictionary.repute-good")
            }}</vs-radio>
          </li>
          <li>
            <vs-radio color="warning" v-model="behaviour.repute" vs-value="0">{{
              $l.get("dictionary.repute-normal")
            }}</vs-radio>
          </li>
          <li>
            <vs-radio color="danger" v-model="behaviour.repute" vs-value="-1">{{
              $l.get("dictionary.repute-bad")
            }}</vs-radio>
          </li>
        </ul>
        <br />
        <div>
          <label for>{{ $l.get("behaviour-form.input-is-published") }}</label>
          <vs-switch v-model="behaviour.isPublished" />
        </div>
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("behaviour-form.card-header-summary") }}</h3>
        </div>
        <div>
          <vs-textarea
            :label="$l.get('behaviour-form.input-summary-en-us')"
            v-model="behaviour.summaryEnUs"
          />
        </div>
        <div>
          <vs-textarea
            :label="$l.get('behaviour-form.input-summary-pt-br')"
            v-model="behaviour.summaryPtBr"
          />
        </div>
        <div>
          <vs-textarea
            :label="$l.get('behaviour-form.input-summary-es-es')"
            v-model="behaviour.summaryEsEs"
          />
        </div>
      </vs-card>
      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>
    <vs-card v-if="behaviour.id">
      <div slot="header">
        <h3>{{ $l.get("behaviour-form.card-header-figure") }}</h3>
      </div>
      <div style="text-align: center">
        <img
          :src="behaviour.figure"
          v-if="behaviour.figure"
          style="max-width: 100%; max-height: 256px"
        />
      </div>
      <vs-upload
        :action="uploadAddress"
        :headers="uploadHeaders"
        fileName="figure"
        :limit="1"
        :show-upload-button="true"
        :automatic="true"
        accept=".jpg, .png"
        :text="$l.get('behaviour-form.input-label-figure', 'upper')"
      />
    </vs-card>
  </div>
</template>

<script>
import GenosetInput from "@/application/components/dna/GenosetInput";
import GenotypeInput from "@/application/components/dna/GenotypeInput";

export default {
  name: "behaviour-form",
  components: { GenosetInput, GenotypeInput },
  props: ["behaviour", "onSubmit"],
  data() {
    return {
      isValid: false,
      genosetErrors: [],
      genotypeErrors: [],
      session: null,
      gateway: null
    };
  },
  beforeMount() {
    var sessionGateway = this.$injector.get("SessionGateway");
    this.session = sessionGateway.get();
    this.gateway = this.$injector.get("BehaviourGateway");
  },
  computed: {
    initialGenoset() {
      if (this.behaviour && this.behaviour.genoset) {
        return this.behaviour.genoset.name;
      } else {
        return "";
      }
    },
    initialGenotype() {
      if (
        this.behaviour &&
        this.behaviour.genotype &&
        this.behaviour.genotype.snp
      ) {
        const genotype = this.behaviour.genotype;
        return `${genotype.snp.rsid}(${genotype.alleles})`;
      } else {
        return "";
      }
    },
    uploadAddress() {
      return `${this.gateway.URL}/${this.$route.params.id}`;
    },
    uploadHeaders() {
      return this.session.tokens.getHeader();
    }
  },
  methods: {
    onGenosetChange(genosetId, value) {
      this.behaviour.genoset = genosetId;
      this.isValid = value.length < 1 || genosetId != null;
    },
    onGenotypeChange(snpId, alleles, value, genotypes) {
      this.isValid =
        value.length < 1 || (genotypes != null && genotypes.length > 0);
      if (!this.isValid) {
        this.genotypeErrors.push(this.$l.get("errors.genotype-not-found"));
      } else if (genotypes != null) {
        this.behaviour.genotype = genotypes[0].id;
      }
    }
  }
};
</script>

<style lang="scss">
.behaviour-form .vs-con-input-label {
  width: 100% !important;
}
</style>
