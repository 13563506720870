<template>
  <div class="behaviour-edit">
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("behaviour-edit.header") }}</h3>

      <BehaviourForm
        :behaviour="data.behaviour"
        :onSubmit="onSubmitForm"
        v-if="data && data.behaviour"
      />

      <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
        <vs-button color="primary" type="filled" @click="onSubmitForm()">{{
          $l.get("dictionary.submit-edit")
        }}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/behaviour-edit/submit-form";
import InitBehaviourEditScreenInteractor from "@/business/behaviour-edit/init-behaviour-edit-screen";
import BehaviourEditScreenController from "@/adapters/controllers/screen-behaviour-edit";
import Loader from "@/application/components/Loader";
import BehaviourForm from "@/application/components/behaviours/BehaviourForm";
// import AutoComplete from "@/application/components/AutoComplete";

export default {
  screen_name: "behaviour-edit",
  data() {
    return {
      controller: null,
      gateways: {
        behaviour: null
      },
      interactors: {
        submitForm: null,
        initBehaviourEditScreen: null
      },
      data: null,
      isLoading: false,
      session: null
    };
  },
  components: { Loader, BehaviourForm },
  beforeMount() {
    this.controller = this.$injector.get(BehaviourEditScreenController);
    this.gateways.behaviour = this.$injector.get("BehaviourGateway");
    const sessionGateway = this.$injector.get("SessionGateway");
    this.session = sessionGateway.get();

    //{ INTERACTORS
    this.interactors.initBehaviourEditScreen = this.$injector.get(
      InitBehaviourEditScreenInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initBehaviourEditScreen.handle(this.$route.params.id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmitForm() {
      const behaviour = this.data.behaviour;
      if (behaviour.genoset && typeof behaviour.genoset == "object") {
        behaviour.genoset = behaviour.genoset.id;
      }
      if (behaviour.genotype && typeof behaviour.genotype == "object") {
        behaviour.genotype = behaviour.genotype.id;
      }
      delete behaviour.figure;
      this.interactors.submitForm.handle(behaviour);
    }
  }
};
</script>

<style lang="scss">
.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
  width: 100% !important;
}
.autocomplete .vs-con-input-label {
  margin-top: 25px;
  width: 100% !important;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.behaviour-edit .con-img-upload .con-input-upload {
  width: 100% !important;
  margin: 5px;
  // background: rgba(var(--vs-primary), 0.15) !important;
  box-shadow: 0px 5px 5px rgba(var(--vs-primary), 0.5);
}
.behaviour-edit .con-img-upload .con-input-upload:hover {
  box-shadow: 0px 10px 5px rgba(var(--vs-primary), 0.5);
}
.behaviour-edit .con-img-upload .con-input-upload:active {
  box-shadow: 0px 1px 5px rgba(var(--vs-primary), 0.5);
}

.behaviour-edit .con-img-upload .text-input {
  font-size: 12px;
  margin-top: -24px !important;
}

.behaviour-edit .con-img-upload .img-upload {
  display: none;
}
.behaviour-edit .con-img-upload .on-progress-all-upload {
  opacity: 1 !important;
}
.behaviour-edit .con-img-upload .disabled-upload {
  height: 4px !important;
}
</style>
